/* Mixins */
.page-body[data-v-4f925be1]  .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 120px;
  padding: 16px;
}
.page-body[data-v-4f925be1]  .ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px;
}
.page-body .mediaWidth[data-v-4f925be1] {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}
@media only screen and (max-width: 1920px) {
.page-body .mediaWidth[data-v-4f925be1] {
    max-width: 300px;
}
}
@media only screen and (max-width: 1500px) {
.page-body .mediaWidth[data-v-4f925be1] {
    max-width: 140px;
}
}